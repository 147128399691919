import React from 'react';
import './AictivityTextW.css';



function AictivityTextW() {
  return (
    <div className="AictivityTextW">
<div className="AictivityTextW-ActivityHeader" >Aktivität</div>
<div className="AictivityTextW-ActivitiyItem1" >15:15 Geschlossen</div>
<div className="AictivityTextW-ActivitiyItem2" >15:04 Offen</div>
<div className="AictivityTextW-ActivitiyItem3" >14:48 Geschlossen</div>
<div className="AictivityTextW-ActivitiyItem4" >14:48 Geschlossen</div>
    </div>
  );
}

export default AictivityTextW;


