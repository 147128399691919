import React from 'react';
import './Motion.css';

import CountAndStatus from './CountAndStatus.js';
import MotionTimes from './MotionTimes.js';

class Motion extends React.Component {

    constructor(props) {
        super(props);
        var objId = Math.random();
        this.state = {
            baseTag: props.baseTag,
            props: props,
            id: objId,
        };
//        console.log('constructor', objId);
    };

    componentDidMount() {
//        this.getTemperatureData();
//        this.temperatureInterval = setInterval(this.getTemperatureData, 60*1000);
    };

    componentWillUnmount() {
//        if (this.temperatureInterval) {
//            clearInterval(this.temperatureInterval);
//        }
    }

    render(){

        const tag = `${this.state.baseTag}-canvas`;
//        console.log(tag);

        return (
                <div className="Motion">
                <div className="Motion-Heading" >Bewegung</div>

                <CountAndStatus {...this.state.props}/>
                <div className="TimesAndMotionChart">
                <MotionTimes {...this.state.props} />
                <div className="Motion-Chart">
                <canvas className="Motion-Chart-Canvas" id={tag}></canvas>
                </div>
                </div>

                </div>
        );
    }

};

export default Motion;
