import React from 'react';
import './CountAndStatus.css';

import Person from '../images/person.png';
//import Status from './Status.js';

function CountAndStatus(props) {
    
    const tag = props.baseTag + '-text';
//    console.log(tag);
    
  return (
          <div className="CountAndStatus">
          <div className="CountAndStatus-Counter" id={tag}>8</div>
          <img src={Person} className="CountAndStatus-Person" alt="" />
          </div>
  );
//    <Status />
}

export default CountAndStatus;


