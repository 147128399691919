import React from 'react';
import './SecondColumn.css';

import TimeSeries from './TimeSeries.js';

function SecondColumn() {
    
    const props_temperatrure = {
        baseTag: "temperature",
        heading: "Temperatur",
        subheading: "...",
    };
    
    const props_co2 = {
        baseTag: "co2",
        heading: "CO2",
        subheading: "...",
    };

    const props_humidity = {
        baseTag: "humidity",
        heading: "Luftfeuchtigkeit",
        subheading: "...",
    };

    return (
            <div className="SecondColumn">
            <TimeSeries {...props_temperatrure} />
            <TimeSeries {...props_co2} />
            <TimeSeries {...props_humidity} />
            </div>
    );
}

export default SecondColumn;


