import React from 'react';
import './WindowStatus.css';

import WindowAndActivity from './WindowAndActivity.js';
import WindowOpen from '../images/window open slice.png';
//import WindowClosed from '../images/window closed slice.png';

function WindowStatus() {
  return (
          <div className="WindowStatus">
          <div className="WindowStatus-Heading" >Fenster</div>
          
          <img src={WindowOpen} className="WindowAndActivity-Window" alt="" />
          
          <div className="WindowStatus-ActivityStatus" >Offen</div>
          <WindowAndActivity />
          </div>
  );
}

export default WindowStatus;


//import WindowOpen from '../images/window open slice.png';
//import WindowClosed from '../images/window closed slice.png';
//<img src={WindowOpen} className="WindowAndActivity-Window" alt="" />
