import React from 'react';
import './TimeAndDate.css';



function TimeAndDate() {
  return (
    <div className="TimeAndDate">
<div className="TimeAndDate-CurrentTime" >15:17:03</div>
<div className="TimeAndDate-DayNameAndDate" >Montag, 3. Februar 2020</div>
    </div>
  );
}

export default TimeAndDate;


