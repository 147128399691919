import React from 'react';
import Chart from 'chart.js';
import {defaults} from 'react-chartjs-2';

import './Cards.css';

import WindowOpen from '../images/window open slice.png';
import WindowClosed from '../images/window closed slice.png';

import FirstColumn from './FirstColumn.js';
import SecondColumn from './SecondColumn.js';

defaults.global.legend.display = false;
defaults.global.title.display = false;
defaults.global.tooltips.enabled = false;

const chartOptions = {
    maintainAspectRatio: false,
    layout: {padding:{top:1}},
    scales: {
        xAxes: [{display: false}],
        yAxes: [{display: false}]
    }
};

class Cards extends React.Component {

    async setTimeAndDate() {
        console.log('setTimeAndDate');

        var now = new Date();
        const options = { weekday: 'long', month: 'long', day: 'numeric' , year: 'numeric'};
        var today_de = now.toLocaleDateString('de-DE', options);
        var timeMatch = now.toString().match(/\d\d:\d\d:\d\d/);
//        console.log(today_de);
//        console.log(timeMatch);

        var timeTextIn = document.getElementsByClassName("TimeAndDate-CurrentTime")[0];
        var dateTextIn = document.getElementsByClassName("TimeAndDate-DayNameAndDate")[0];
        timeTextIn.textContent = timeMatch;
        dateTextIn.textContent = today_de;
    }

    async getCardsData() {
        console.log('getCardsData');

        // times
        function setTimes(tag) {
//            console.log('setTimes,', tag);

            var now = new Date();
            var timeText = document.getElementById(`${tag}-time5`);
            timeText.textContent = now.toTimeString().match(/\d+:\d+/)[0];

            function setTime(i) {
                now = new Date(now.setHours(now.getHours()-1));
                var timeText = document.getElementById(`${tag}-time${i}`);
                timeText.textContent = now.toTimeString().match(/\d+:\d+/)[0];
            }
            [4,3,2,1].forEach(setTime);
        }
        ['temperature', 'co2', 'humidity', 'motion'].forEach(setTimes);



        // device_frmpayload_data_co2, Elsys-ERSCO2-Unterwegs
        // "device_frmpayload_data_co2,application_name=Koffer-Luebeck,dev_eui=a81758fffe048dc9,device_name=ersco2-koffer-luebeck,f_port=5"
        var co2_values = await this.getSensorData("device_frmpayload_data_co2",
                                                  "ersco2-koffer-kiel",
                                                  48);
        co2_values = co2_values.reverse();
//        console.log(co2_values);

        // device_frmpayload_data_temperature, Elsys-ERSCO2-Unterwegs
        // "device_frmpayload_data_temperature,application_name=Koffer-Luebeck,dev_eui=a81758fffe048dc9,device_name=ersco2-koffer-luebeck,f_port=5"
        var temperature_values = await this.getSensorData("device_frmpayload_data_temperature",
                                                          "ersco2-koffer-kiel",
                                                          48);
        temperature_values = temperature_values.reverse();
//        console.log(temperature_values);

        // device_frmpayload_data_humidity, Elsys-ERSCO2-Unterwegs
        // "device_frmpayload_data_humidity,application_name=Koffer-Luebeck,dev_eui=a81758fffe048dc9,device_name=ersco2-koffer-luebeck,f_port=5"
        var humidity_values = await this.getSensorData("device_frmpayload_data_humidity",
                                                       "ersco2-koffer-kiel",
                                                       48);
        humidity_values = humidity_values.reverse();
//        console.log(humidity_values);

       // device_frmpayload_data_motion, Elsys-ERSCO2-Unterwegs
       // "device_frmpayload_data_motion,application_name=Koffer-Luebeck,dev_eui=a81758fffe048dc9,device_name=ersco2-koffer-luebeck,f_port=5"
       var motion_values = await this.getSensorData("device_frmpayload_data_motion",
                                                    "ersco2-koffer-kiel",
                                                    48);
        // // device_frmpayload_data_count, Tabs-Motion-Sensor-Unterwegs
        // // 49 counts, results in 48 differences
        // var motion_values = await this.getSensorData("device_frmpayload_data_count",
        //                                              "Tabs-Motion-Sensor-Unterwegs",
        //                                              49);
//        console.log(motion_values);

        // chart
        function createChart(tag) {
//            console.log('createChart,', tag);

            var data = null;
            var units = '';

            var chartDataset = {
                labels: [1,2,3,4,5,6,7,8,9,10,11,12, 1,2,3,4,5,6,7,8,9,10,11,12, 1,2,3,4,5,6,7,8,9,10,11,12, 1,2,3,4,5,6,7,8,9,10,11,12],
                datasets: [{
                            backgroundColor: '#536A73',
                            borderColor:'#6EA4B9',
                            borderWidth: 2,
                            fill: 'start',
                            pointRadius: 0,
                            data: null
                           }]
            };

            switch (tag) {
                    case 'temperature':
//                        console.log('switch, case,', tag);
                        data = temperature_values.map(v => v[1]);
                        units = '°C';
                        break;
                    case 'co2':
//                        console.log('switch, case,', tag);
                        data = co2_values.map(v => v[1]);
                        units = 'ppm';
                        break;
                    case 'humidity':
//                        console.log('switch, case,', tag);
                        data = humidity_values.map(v => v[1]);
                        units = '%';
                        break;
                    default:
                        break;
            };

            // canvas
            var canvas = document.getElementById(`${tag}-canvas`);
            var ctx = canvas.getContext('2d');
//            console.log(canvas);
//            console.log(ctx);

            chartDataset.datasets[0].data = data;
            new Chart(ctx, {
                      type: 'line',
                      data: chartDataset,
                      options: chartOptions
                      });

            // status
            var status = document.getElementById(`${tag}-status`);
            status.textContent = data[data.length-1] + ' ' + units;

        }
        ['temperature', 'co2', 'humidity'].forEach(createChart);

        // prepare motion values, time interval, 4 hours
        // split in 5 minute chunks, results in length of 48
        var motionValuesData = Array(48).fill(0);
        var motionTimes = motion_values.map(v => v[0]);
        var motionValues = motion_values.map(v => v[1]);
        // var motionValuesDiff = motionValues.slice(1).map((v,i) => (motionValues[i] - v));
        var motionValuesDiff = motionValues;

        var dateNow = Date.now();
        function index(t) {
            return Math.round((dateNow - Date.parse(t))/(5*60*1000));
        };
        var motionValuesIndex = motionTimes.slice(0,-1).map(index);
        motionValuesIndex.forEach((i,j) => {if (i < 48) motionValuesData[i] = motionValuesDiff[j];});

//        console.log(motionValuesData);
//        console.log(motionTimes);
//        console.log(motionValues);
//        console.log(motionValuesDiff);
//        console.log(motionValuesIndex);

        // chart motion
        var motionDataset = {
            labels: [1,2,3,4,5,6,7,8,9,10,11,12, 1,2,3,4,5,6,7,8,9,10,11,12, 1,2,3,4,5,6,7,8,9,10,11,12, 1,2,3,4,5,6,7,8,9,10,11,12],
//            labels: timeLabels,
            datasets: [{
                        backgroundColor: '#536A73',
//                        borderColor:'#6EA4B9',
//                        borderColor:'#536A73',
//                        borderWidth: 2,
//                        fill: 'start',
                        pointRadius: 0,
//                        scaleSteps: 1,
//                        data: motion_values.map(v => v[1]),
                           data: motionValuesData,
                       }]
        };

        const motionOptions = {
            maintainAspectRatio: false,
            layout: {padding:{top:1}},
            scales: {
//                xAxes: [{display: false}],
//                xAxes: [{gridLines: { color: "#131c2b" }}],
//                yAxes: [{gridLines: { color: "#131c2b" }}]
                xAxes: [{
//                        type: 'time',
                        display: true,
                        position: 'top',
                        ticks: {
                            fontColor: 'rgba(255,255,255,0.75)',
                            fontFamily: 'Roboto',
                            fontSize: 16,
                            precision: 0,
                        },
                        gridLines: {
                            borderDash: [5,5],
                            zeroLineBorderDash: [5,5],
                            color: 'rgba(129, 213, 245, 0.10)',// "#81D5F5",
                            zeroLineColor: 'rgba(129, 213, 245, 0.10)',
                        }}],
                yAxes: [{
                        display: false,
//                        gridLines: {
//                            borderDash: [5,5],
////                            zeroLineColor: 'rgba(129, 213, 245, 0.0)',
//                        }
                        }]
            }
        };

        // canvas
        var canvas = document.getElementById('motion-canvas');
        var ctx = canvas.getContext('2d');
        new Chart(ctx, {
                  type: 'horizontalBar',
                  data: motionDataset,
//                  data: motion_values,
                  options: motionOptions
                  });

        // text
//        var motion_values = elsys_values.map(v => v[4]).reverse();
        var motion_text = document.getElementById('motion-text');
        motion_text.textContent = motionValuesData[0];
//        console.log(motion_values);
//        console.log(motion_values[0]);

    };

    async getSensorData(dataName, deviceName, limit) {
//        console.log("getSensorData", dataName, deviceName, limit);

        var query = '';
        query += `select value from ${dataName} `;
        query += `where device_name='${deviceName}' `;
        query += `order by desc limit ${limit}`;

        var url = '';
        url += 'https://static.250.220.203.116.clients.your-server.de:8086/query?db=scotogo&';
        url += 'q=' + encodeURIComponent(query);

        const response = await fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            redirect: 'follow',
            agent: null,
            headers: {
                "Content-Type": "text/plain",
                'Authorization': 'Basic ' + btoa('client_read:R#EADI@NFlU20'),
            },
            timeout: 5000
        });
        const json = await response.json();
       console.log(json);
        var value_matrix = json.results[0].series[0].values;
        return value_matrix;
    };

    async getWindowData() {
        console.log('getWindowData');

        // tuerkontakt
        // ",application_name=Koffer-Luebeck,dev_eui=0e7e34643330a714,device_name=cm868lrth-koffer-luebeck,f_port=30"
        var tuer_values = await this.getSensorData("device_frmpayload_data_Event_reed_sensor",
                                                   "cm868lrth-koffer-kiel",
                                                   4);
//        console.log(tuer_values);

        // last status
        var windowImage = document.getElementsByClassName("WindowAndActivity-Window")[0];
        var windowStatus = document.getElementsByClassName("WindowStatus-ActivityStatus")[0];
        if (tuer_values[0][1] === 1) {
            // open
            windowImage.src = WindowOpen;
            windowStatus.textContent = 'Offen';
            windowStatus.style.color = '#FFB266';
        } else {
            // closed
            windowImage.src = WindowClosed;
            windowStatus.textContent = 'Geschlossen'
            windowStatus.style.color = '#00D97E';
        }

        function setWindowActivity(i) {

            var tuer_value = tuer_values[i-1];
            var tuer_time = (new Date(tuer_value[0])).toString().match(/\d\d:\d\d/)[0];
            var tuer_on_off = tuer_values[i-1][1];

            var tuer_text = '';
            if (tuer_on_off === 0) {
                tuer_text = 'Geschlossen';
            } else {
                tuer_text = 'Offen';
            }

            var status = document.getElementsByClassName(`AictivityTextW-ActivitiyItem${i}`)[0];
            status.textContent = tuer_time + ' ' + tuer_text;

        }
        [1,2,3,4].forEach(setWindowActivity);

    };

    constructor(props) {
        super(props);
        var objId = Math.random();
        this.state = {
            id: objId,
        };
//        console.log('Cards, id:', objId);

        this.getWindowData = this.getWindowData.bind(this);
        this.getCardsData = this.getCardsData.bind(this);

    };

    componentDidMount() {
        this.setTimeAndDate();
        this.getCardsData();
        this.getWindowData();
        this.timeInterval = setInterval(this.setTimeAndDate, 1*1000);
        this.cardsInterval = setInterval(this.getCardsData, 60*1000);
        this.windowInterval = setInterval(this.getWindowData, 5*1000);
    };

    componentWillUnmount() {
        if (this.windowInterval) {
            clearInterval(this.windowInterval);
        }
        if (this.cardsInterval) {
            clearInterval(this.cardsInterval);
        }
        if (this.timeInterval) {
            clearInterval(this.timeInterval);
        }
    }

    render(){
        return (
                <div className="Cards">
                <FirstColumn />
                <SecondColumn />
                </div>
                );
    };

};

export default Cards;
