import React from "react";
import "./Header.css";
import SCOLogo from "../images/SCOLogo.png";

function Header() {
  return (
    <div className="Header">
      <a href="https://www.smart-city-operations.de">
        <img src={SCOLogo} className="Header-SCOLogo" alt="SCO-Logo" target="_blank" rel="noopener noreferrer"/>
      </a>
      <div className="Header-Text">Smart City To Go <code className="Header-Code">#02</code></div>
    </div>
  );
}

export default Header;
